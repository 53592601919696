import Mustache from "mustache";
import Dashboard from "@uppy/dashboard";
import XHRUpload from "@uppy/xhr-upload";
import GoogleDrive from "@uppy/google-drive";

var uppyObjects = {};

const instantiateUppy = (parent) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  return new window.Uppy({
    debug: true,
  })
    .use(Dashboard, {
      // target: '#'+parent.id,
      inline: false,
      trigger: ".upload-btn",
      id: parent.id,
    })
    .use(GoogleDrive, {
      target: Dashboard,
      companionUrl:
        window.env.UPPY_COMPANION_SERVER_URL || "http://localhost:3020",
    })
    .use(XHRUpload, {
      endpoint: `${window.env.APPLICATION_URL}/uploader/image`,
      bundle: false,
      allowedMetaFields: ["name"],
      headers: { "X-CSRF-Token": csrfToken },
    });
};
const updateActions = (parent, uppy) => {
  const input = parent.parentNode.querySelector("input[type=file]");
  uppy.on("upload-success", (file, body) => {
    uppy.setFileState(file.id, {
      xhr: Object.assign({}, file, {
        uploadURL: body.body["uploadURL"],
        signedId: body.body["signedId"],
      }),
    });
  });

  uppy.on("complete", (result) => {
    result.successful.forEach((file) => {
      insertImageSignedId(input, file.xhr.signedId);
      insertPreviewImage(parent, file.name, file.preview, file.type);
    });
  });
};

const createOrUpdateUppy = (parent) => {
  uppy = instantiateUppy(parent);
  updateActions(parent, uppy);
  uppyObjects[parent.id] = uppy;
  return uppy;
};

const insertImageSignedId = (input, signed_id) => {
  const hiddenField = document.createElement("input");
  hiddenField.setAttribute("type", "hidden");
  hiddenField.setAttribute("value", signed_id);
  hiddenField.name = input.name;
  input.form.appendChild(hiddenField);
};

const imageTemplate = (name, url, type) => {
  const template = `
  <div class="uppy-uploaded-files__block">
    {{#isImage}}
      <img src="{{url}}">
    {{/isImage}}
    {{^isImage}}
      <i class="far fa-file fa-10x"></i>
    {{/isImage}}
    <span>{{name}}</span>
  </div>
  `;
  return Mustache.render(template, {
    name: name,
    url: url,
    isImage: type.startsWith("image"),
  });
};

const insertPreviewImage = (parent, name, url, type) => {
  const imagePreviewBlocks = parent.parentNode.querySelector(
    '[id^="js-uppy-preview-"]'
  );
  imagePreviewBlocks.insertAdjacentHTML(
    "beforeend",
    imageTemplate(name, url, type)
  );
};

$(document).on("turbo:load", function () {
  document.querySelectorAll('[id^="js-uppy-upload-"]').forEach((element) => {
    createOrUpdateUppy(element);
  });
  document.querySelectorAll('[id^="js-uppy-upload-"]').forEach((element) => {
    element.addEventListener("click", function () {
      uppyObjects[element.id];
    });
  });
});
