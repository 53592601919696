import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fulfillment--print-envelopes"
export default class extends Controller {

  validateAndOpen(event) {
    const orderIds = Array.from(
      document.querySelectorAll(".orders_id:checked")
    ).map((input) => input.value);

    console.log(orderIds);

    const fromAddressBookId = document.querySelector("#from_address_book_id").value;
    console.log(fromAddressBookId);

    if (orderIds.length === 0) {
      alert("No orders selected.");
      return;
    }

    if (!fromAddressBookId) {
      alert("Please select a 'From Address Book'.");
      return;
    }

    const url = `/paige/orders/fulfillment/print_envelopes?order_ids=${orderIds.join(",")}&from_address_book_id=${fromAddressBookId}`;

    window.location.href = url;
  }
}

